import * as React from "react";
import { Alert, Button, Form } from "react-bootstrap";

const formMaxWidth = {
  maxWidth: "520px",
  margin: "auto",
};

export default class ContactForm extends React.Component {
  state = {
    imieNazwisko: "",
    pocztaElektroniczna: "",
    telefon: "",
    wiadomosc: "",
    politykaPrywatnosci: false,
    przetwarzanieDanych: false,
    errors: {},
    formSending: false,
    formSent: false,
    formError: false,
  }

  render() {
    const errors = this.state.errors || {};
    return (
      <Form noValidate onSubmit={this.handleSubmit} style={formMaxWidth}>
        <Form.Group controlId="formImieNazwisko">
          <Form.Label>Imię i nazwisko</Form.Label>
          <Form.Control
            type="text"
            name="imieNazwisko"
            value={this.state.imieNazwisko}
            onChange={this.handleInputChange}
            isValid={this.state.formSent}
            disabled={this.state.formSending}
          />
        </Form.Group>
        <Form.Group controlId="formPocztaElektroniczna">
          <Form.Label>Adres e-mail</Form.Label>
          <Form.Control
            type="text"
            name="pocztaElektroniczna"
            value={this.state.pocztaElektroniczna}
            onChange={this.handleInputChange}
            isValid={this.state.formSent}
            isInvalid={!!errors.pocztaElektroniczna}
            disabled={this.state.formSending}
          />
          <Form.Control.Feedback type="invalid">
            {errors.pocztaElektroniczna}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formTelefon">
          <Form.Label>Telefon</Form.Label>
          <Form.Control
            type="text"
            name="telefon"
            value={this.state.telefon}
            onChange={this.handleInputChange}
            isValid={this.state.formSent}
            isInvalid={!!errors.telefon}
            disabled={this.state.formSending}
          />
          <Form.Control.Feedback type="invalid">
            {errors.telefon}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formWiadomosc">
          <Form.Label>Wiadomość</Form.Label>
          <Form.Control
            type="text"
            name="wiadomosc"
            value={this.state.wiadomosc}
            onChange={this.handleInputChange}
            isValid={this.state.formSent}
            disabled={this.state.formSending}
          />
        </Form.Group>
        <Form.Group>
          Informacje o zasadach przetwarzania Państwa danych osobowych znajdują się w <a href="\PolitykaPrywatnosciONVO.html" target="_blank" style={{textDecoration: 'underline'}}>Polityce Prywatności</a>.
        </Form.Group>
        <Form.Group>
          <Form.Check type="checkbox" id="formPolitykaPrywatnosci">
            <Form.Check.Input
              type="checkbox"
              name="politykaPrywatnosci"
              checked={this.state.politykaPrywatnosci}
              onChange={this.handleCheckboxChange}
              isInvalid={!!errors.politykaPrywatnosci}
              isValid={this.state.formSent}
            />
            <Form.Check.Label>Oświadczam, że zapoznałem się z <a href="\PolitykaPrywatnosciONVO.html" target="_blank" style={{textDecoration: 'underline'}}>Polityką Prywatności</a> i akceptuję jej treść.</Form.Check.Label>
            <Form.Control.Feedback type="invalid">
              {errors.politykaPrywatnosci}
            </Form.Control.Feedback>
          </Form.Check>
        </Form.Group>
        <Form.Group>
          <Form.Check type="checkbox" id="formPrzetwarzanieDanych">
            <Form.Check.Input
              type="checkbox"
              name="przetwarzanieDanych"
              checked={this.state.przetwarzanieDanych}
              onChange={this.handleCheckboxChange}
              isInvalid={!!errors.przetwarzanieDanych}
              isValid={this.state.formSent}
            />
            <Form.Check.Label>Wyrażam zgodę na przetwarzanie moich danych osobowych przez ONVO sp. z o.o. z siedzibą w Krakowie (31-539) przy Al. Powstania Warszawskiego 15 (KRS: 0000875138) w celu odpowiedzi na przesłaną przeze mnie wiadomość, poprzez kontakt telefoniczny lub wiadomość e-mail.</Form.Check.Label>
            <Form.Control.Feedback type="invalid">
              {errors.przetwarzanieDanych}
            </Form.Control.Feedback>
          </Form.Check>
        </Form.Group>

        {this.state.formSent &&
          <Alert variant="success" className="mt-3">
            Dziękujęmy za wysłanie formularza! Skontaktujemy się z Tobą jak tylko to będzie możliwe.
          </Alert>
        }
        {this.state.formError &&
          <Alert variant="danger" className="mt-3">
            Coś poszło nie tak podczas wysyłania formularza. Przepraszamy. Spróbuj ponownie lub napisz do nas na biuro@onvo.pl.
          </Alert>
        }
        {(errors.telefon || errors.pocztaElektroniczna) &&
          <Alert variant="danger" className="mt-3">
            {[errors.pocztaElektroniczna, errors.telefon, errors.politykaPrywatnosci, errors.przetwarzanieDanych].join(' ')}
          </Alert>
        }
        {!this.state.formSent &&
          <Button type="submit" disabled={this.state.formSending}>
            {this.state.formSending ? "Wysyłam..." : "Wyślij formularz"}
          </Button>
        }
        {this.state.formSent &&
          <Button onClick={this.clearForm}>
            Wyczyść formularz
          </Button>
        }
      </Form>
    )
  };

  handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.clearFormSendingMarkers();
    this.setState({
      [name]: value,
    })
  };

  handleCheckboxChange = (event) => {
    const target = event.target
    const checked = target.checked
    const name = target.name
    this.clearFormSendingMarkers();
    this.setState({
      [name]: checked,
    })
  };

  clearForm = () => {
    this.clearFormSendingMarkers();
    this.setState({
      imieNazwisko: "",
      pocztaElektroniczna: "",
      telefon: "",
      wiadomosc: "",
      politykaPrywatnosci: false,
      przetwarzanieDanych: false,
    })
  }

  clearFormSendingMarkers = () => {
    this.setState({
      errors: {},
      formSending: false,
      formSent: false,
      formError: false,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.clearFormSendingMarkers();
    const errors = {};

    if (this.state.pocztaElektroniczna && !this.emailIsValid(this.state.pocztaElektroniczna)) {
      errors.pocztaElektroniczna = "Podaj poprawny adres e-mail w postaci 'użytkownik@domena.kodKraju', żebyśmy mogli się z Tobą skontaktować.";
    }

    if (this.state.telefon && this.state.telefon.length < 5) {
      errors.telefon = "Podaj poprawny numer telefonu, żebyśmy mogli się z Tobą skontaktować.";
    }

    if (!this.state.pocztaElektroniczna && !this.state.telefon) {
      errors.pocztaElektroniczna = "Podaj adres e-mail, jeśli chcesz, żebyśmy do Ciebie napisali.";
      errors.telefon = "Podaj numer telefonu, jeśli chcesz, żebyśmy do Ciebie oddzwonili.";
    }

    if (!this.state.politykaPrywatnosci) {
      errors.politykaPrywatnosci = "Zapoznaj się z Polityką Prywatności i zaznacz checkbox."
    }

    if (!this.state.przetwarzanieDanych) {
      errors.przetwarzanieDanych = "Zaznacz zgodę na przetwarzanie Twoich danych osobowych, żebyśmy mogli się z Tobą skontakotwać.";
    }

    if (errors.pocztaElektroniczna || errors.telefon || errors.politykaPrywatnosci || errors.przetwarzanieDanych) {
      this.setState({
        errors
      })
      return;
    }

    this.setState({ formSending: true });

    fetch(`/sendForm.php`, {
      method: "POST",
      cache: "no-store",
      body: this.getFormData(),
    })
      .then((response) => {
        if (response.status === 200) {
          this.setState({ formSending: false, formError: false, formSent: true });
        }
        else {
          this.setState({ formSending: false, formError: true, formSent: false });
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ formSending: false, formError: true, formSent: false });
      });
  };

  getFormData = () => {
    const formData = new FormData();
    formData.append('imieNazwisko', this.state.imieNazwisko ?? '');
    formData.append('pocztaElektroniczna', this.state.pocztaElektroniczna ?? '');
    formData.append('telefon', this.state.telefon ?? '');
    formData.append('wiadomosc', this.state.wiadomosc ?? '');
    formData.append('politykaPrywatnosci', this.state.politykaPrywatnosci ? 'tak' : 'nie');
    formData.append('przetwarzanieDanych', this.state.przetwarzanieDanych ? 'tak' : 'nie');
    return formData;
  }

  emailIsValid = (email) => {
    if (email && email.indexOf("@") > 0) {
      if (email.lastIndexOf(".") > (email.indexOf("@") + 1)) {
        return true;
      }
    }
    return false;
  }
};